body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", sans-serif;
}

::selection{
  background-color: #DCA424;
  color: #f7f7f7;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
  padding: 0 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #DCA424;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2F327D;
  border-radius: 10px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #a77d1b;
} */
